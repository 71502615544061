<template>
    <div />    
</template>

<script>
export default {
    mounted(){
        if(!this.$store.state.auth0.isAuthenticated)
            this.$auth0.loginWithRedirect();
    }
}
</script>